import { resolveEditorConfig, runCommand } from "./GenericEditorProviderShared"
import * as R from "ramda"
import { DateTime } from "luxon"
import { toDevIdString } from "../shared/VersionId"
import { asyncConfirm, asyncShowDashBoard, getAsyncDashBoardEvent } from "./GenericEditorProviderAsyncMethods"
import { GenericEditorConfig } from "./GenericEditorConfigProviderInterfaces"
import deepEqual from "deep-equal-ignore-functions"

const isNilOrEmpty = x => R.isNil(x) || R.isEmpty(x)

export const dataPipeDsMapper = (providers, descriptorProviderName, i) => {
    const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
    return {
        libraryEntryType: editorConfig.libraryEntryType,
        libraryId: i.params.dashBoardId
    }
}

export const dataPipeLoadStateWatcherMapper = (providers, descriptorProviderName, context) => {
    return { value: context.params.loadState }
}

export const dataPipeLoadStateWatcherOnChange = (providers, descriptorProviderName, context, loadState, prevLoadState) => {
    if (loadState === "dataLoading") {
        const { loadParams } = context.params
        runCommand(providers, descriptorProviderName, context, "load", loadParams, (editorConfig: GenericEditorConfig, commandData, result) => {
            if (result.statusCode === 200 && !isNilOrEmpty(result.body)) {
                providers.dashBoardState({
                    data: result.body,
                    loadState: "dataLoaded"
                })

            } else {
                const { statusCode } = result
                const errorMessage = statusCode === 404 ? "Entry does not exist." : "Failed to load entry."
                providers.notify("Failed to load data", errorMessage, { type: "error" })
                providers.dashBoardState({
                    loadState: "dataLoadError",
                    dataLoadErrorStatusCode: statusCode,
                    dataLoadErrorMessage: errorMessage
                })
            }
        })
    }

}

export const dataPipeMapper = (providers, descriptorProviderName, context) => {

    const dashBoardId = R.path(["params", "dashBoardId"], context) as string
    const data: any = R.path(["params", "data"], context)
    const loadState: string = R.pathOr("init", ["params", "loadState"], context)
    const saved = R.pathOr(false, ["params", "saved"], context)
    const useDevVersion = R.path(["params", "useDevVersion"], context)
    const changeDetails = R.path(["params", "changeDetails"], context)
    let statePaths: any = {}

    if (loadState === "init") {
        const editorConfig = resolveEditorConfig(providers, descriptorProviderName)
        statePaths.previewAvailable = false
        statePaths.requestedId = dashBoardId
        statePaths.devId = toDevIdString(dashBoardId)
        statePaths.loadParams = {
            libraryEntryType: editorConfig.libraryEntryType,
            libraryId: dashBoardId
        }
        statePaths.loadState = "dataLoading"
    }

    if (loadState === "dataLoaded") {
        if (data.hasDevelopmentVersion) {
            const { changeDetails } = data.developmentVersion.properties
            let changeMessage = ""
            if (changeDetails) {
                const by = R.propOr("Unknown", "changedBy", changeDetails)
                const ts = R.propOr("invalid", "timestamp", changeDetails) as string
                const d = DateTime.fromMillis(parseInt(ts, 10))
                const on = d.isValid ? d.toFormat("DD") : "---"
                const reason = R.propOr("No Comment", "changeReason", changeDetails)
                changeMessage = `\n&nbsp;\n**${by}, ${on}**\n"${reason}"`
            }
            statePaths = R.merge(statePaths, asyncConfirm(
                `## Development Version Available${changeMessage}\n&nbsp;\nPress **OK** to edit the development version, or **CANCEL** to edit the published version.`,
                "useDevVersionAnswer"
            ))
            statePaths.useDevVersionAnswer = null
            statePaths.loadState = "awaitingUseDevVersionAnswer"

        } else {
            const verPath = ["properties", "publishVersion"]
            statePaths.loadState = "enterChangeDetails"

        }
    }

    if (loadState === "enterChangeDetails") {
        statePaths = R.merge(statePaths, asyncShowDashBoard({
            dashBoardUrl: "/id/00000000-2222-0000-9999-000000000005",
            dashBoardParams: {
                title: "Please describe your intended changes",
                okButtonOptions: {
                    text: "start editing",
                },
                cancelButtonOptions: {
                    text: "View Published Version",
                    type: "warningIndicator"
                }
            }
        }))
        statePaths.checkoutDetailsResponse = null
        statePaths.loadState = "awaitingChangeDetails"
    }

    if (loadState === "awaitingChangeDetails") {
        const event = getAsyncDashBoardEvent(providers, context)
        if (!R.isNil(event)) {
            const { eventName, eventData } = event
            statePaths.changeDetails = R.isNil(eventData) ?
                null : R.assoc("timestamp", Date.now(), eventData)
            statePaths.loadState = "dataReady"
            statePaths.useDevVersion = false
        }
    }

    if (loadState === "awaitingUseDevVersionAnswer") {
        const answer = R.path(["params", "useDevVersionAnswer"], context)
        if (answer) {
            statePaths.loadState = "dataReady"
            statePaths.useDevVersion = answer === "ok"
            if (!statePaths.useDevVersion) statePaths.loadState = "enterChangeDetails"
        }
    }

    if (loadState === "dataReady") {
        statePaths.loadedData = data
        statePaths.editingDashBoard = useDevVersion ? data.developmentVersion : data.editingVersion
        statePaths.previewAvailable = !!(useDevVersion && data.developmentVersion)
        if (!useDevVersion) {
            if (changeDetails)
                statePaths.editingDashBoard = R.assocPath(["properties", "changeDetails"], changeDetails, statePaths.editingDashBoard)
            statePaths.isReadonly = !changeDetails
        }
        statePaths.compareVersion = R.clone(statePaths.editingDashBoard)
        statePaths.data = null
        statePaths.loadState = "editing"
    }

    if (loadState === "editing") {
        const { editingDashBoard, compareVersion } = context.params
        const unSavedChanges = !deepEqual(editingDashBoard, compareVersion)
        statePaths.unSavedChanges = unSavedChanges
    }

    if (loadState === "saved") {
        const editingDashBoard = R.path(["params", "editingDashBoard"], context)
        statePaths.compareVersion = R.clone(editingDashBoard)
        statePaths.previewAvailable = "true"
        statePaths["loadedData/developmentVersion"] = editingDashBoard
        statePaths.loadState = "editing"
    }

    return { statePaths }

}