import { FormProps } from "@sennen/dashboard-extension-sennen-core"
import { GenericEditorActionItemHandlerArgs } from "../../../genericEditor/GenericEditorConfigProviderInterfaces"
import { JsonSnippetInsertOptions, getLastChainedTemplateData } from "../../../actionItems/JsonInsertSnippetActionItem"
import {
    map, prop, prepend, compose,
    propEq, cond, keys, pathOr, path,
    identity, merge, split, T, flatten,
    uniq, join, findIndex, filter, equals,
    assocPath, always
} from "ramda"
import { requiredStringInput, requiredIdInput, requiredSelect, stringInput, idInput, textArea } from "../../../../helpers/formControls"
import { getRegisteredType } from "../../../clientRegistry/clientRegistry"

const stringToSelectItem = x => identity({ text: x, value: x })
const toSelectItem = (text: string, value: any) => identity({ text, value })
const rootLayoutItem = toSelectItem("Dashboard Root", { isRoot: true })

const getGridLayoutLocations = (layout: any) => {
    const areas = compose<any, any, any, any, any, any>(
        uniq, filter((x: any) => !!x), split(" "), join(" "), pathOr([], ["properties", "areas"])
    )(layout)
    return map(area => toSelectItem(
        `${layout.id} -> ${area}`, { area }
    ), areas)
}

const getTabbedLayoutLocations = (layout: any) => {
    const tabs = pathOr([], ["properties", "tabs"], layout)
    return map(tab => toSelectItem(
        `${layout.id} -> ${tab.description}`, { tab }
    ), tabs)
}

const getLocations = (layout) => cond([
    [equals("GridLayout"), () => getGridLayoutLocations(layout)],
    [equals("TabbedLayout"), () => getTabbedLayoutLocations(layout)],
    [T, () => [toSelectItem(layout.id, {})]]
])(layout.type)

const getLayoutLocations = (data: any) => {
    const layouts = pathOr([], ["entry", "layouts"], data)
    const layoutLocations = map(layout => {
        const { type } = layout
        const locations = getLocations(layout)
        return map(compose(
            assocPath(["value", "id"], layout.id),
            assocPath(["value", "type"], layout.type)
        ), locations)
    }, layouts)

    return flatten(layoutLocations as any) as any
}


//#region templates
const genericLayoutTemplate = async data => {
    const { layoutId, layoutType } = data
    return {
        "id": layoutId,
        "type": layoutType,
        "children": [],
        "properties": {}
    }
}

const responsiveLayoutTemplate = async data => {
    const baseTemplate = await genericLayoutTemplate(data)
    return {
        ...baseTemplate,
        properties: {
            ...baseTemplate.properties,
            debug: false,
            queries: [
                { name: "default", query: true, layoutId: null, dashPartId: null },
                { name: "smallLayout", query: "widthRem > 22", layoutId: null, dashPartId: null },
                { name: "mediumLayout", query: "widthRem > 46", layoutId: null, dashPartId: null }
            ]
        }
    }
}

const tabbedLayoutTemplate = async data => {
    const { tabs } = data
    const template = await genericLayoutTemplate(data)
    return {
        ...template,
        ...{
            "properties": { tabs }
        }
    }
}

const gridLayoutTemplate = async data => {
    const { areas, columns, rows } = data
    const template = await genericLayoutTemplate(data)
    return {
        ...template,
        ...{
            "properties": {
                "areas": split("\n", areas),
                "columns": columns,
                "rows": rows,
            }
        }
    }
}

const baseLayoutChildTemplate = data => {
    const { layoutChildType, dashPartId, layoutId } = data
    return {
        "type": layoutChildType,
        "properties": {},
        "typeProperties": { dashPartId, layoutId }
    }
}

const gridLayoutChildTemplate = data => {
    const { area } = data.layoutLocation
    return { properties: { area, containerSize: "content" } }
}

const tabbedLayoutChildTemplate = data => {
    const { tab } = data.layoutLocation
    return { properties: { "tabId": tab.id, containerSize: "content" } }
}

const appendLayoutChildTemplate = async data => {
    const { layoutLocation } = data
    const { type } = layoutLocation
    const baseLayout = baseLayoutChildTemplate(data)

    const specificLayout = cond<any, any>([
        [equals("GridLayout"), () => gridLayoutChildTemplate(data)],
        [equals("TabbedLayout"), () => tabbedLayoutChildTemplate(data) as any]
    ])(type) // Remove the argument from the function call.
    return merge(baseLayout, specificLayout || {})
}
//#endregion


//#region forms
const genericLayoutForm = async (args: GenericEditorActionItemHandlerArgs) => {
    const lastData = getLastChainedTemplateData(args)
    return { data: lastData, controls: [] } as FormProps
}

const gridLayoutForm = async (args: GenericEditorActionItemHandlerArgs) => {
    const lastData = getLastChainedTemplateData(args)
    {
        return {
            formTitle: "Grid Layout",
            data: merge(lastData, {
                areas: "top top\nleft right\nbottom bottom",
                columns: "1fr 1fr",
                rows: "auto auto auto"
            }),
            controls: [
                textArea("areas", "Areas"),
                stringInput("columns", "Columns"),
                stringInput("rows", "rows")
            ]
        } as FormProps
    }
}

const tabbedLayoutForm = async (args: GenericEditorActionItemHandlerArgs) => {
    const lastData = getLastChainedTemplateData(args)
    {
        return {
            formTitle: "Tabbed Layout",
            data: merge(lastData, {
                tabs: []
            }),
            controls: [
                requiredIdInput("tabs[0].id", "Id 1"),
                requiredStringInput("tabs[0].description", "Description 1"),
                idInput("tabs[1].id", "Id 2"),
                stringInput("tabs[1].description", "Description 2"),
                idInput("tabs[2].id", "Id 3"),
                stringInput("tabs[2].description", "Description 3")

            ]
        } as FormProps
    }
}

const appendLayoutChildForm = async (args: GenericEditorActionItemHandlerArgs) => {
    const lastData = getLastChainedTemplateData(args)
    let layoutLocations = getLayoutLocations(args.data)
    if (lastData.layoutChildType === "layout") {
        layoutLocations = prepend(rootLayoutItem, layoutLocations)
    }
    return {
        data: merge(
            lastData, {
            layoutLocation: null
        }
        )
        , controls: [
            requiredSelect("layoutLocation", "Layout location", layoutLocations)
        ]
    } as FormProps
}

//#endregion

//#region snippets
const newLayoutGeneralSnippet: JsonSnippetInsertOptions = {
    name: "new-layout",
    buttonText: "New Layout",
    buttonIconClassName: "fa fa-th",
    insertLocation: "none",
    insertPath: [],
    insertMode: "prepend",
    template: async data => null,
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const layoutTypes = await getRegisteredType(args.providers, "layouts")
        const layoutTypeItems = map(stringToSelectItem, keys(layoutTypes))
        const layouts = pathOr([], ["data", "entry", "layouts"], args)
        const layoutItems = map(l => identity({
            text: `${prop("id", l)} [${prop("type", l)}]`,
            value: prop("id", l)
        }), layouts) as any[]

        return {
            formTitle: "New Layout",
            data: {
                layoutId: undefined,
                layoutType: pathOr({}, [0], layoutTypeItems)["value"]
            },
            controls: [
                requiredIdInput("layoutId", "ID"),
                requiredSelect("layoutType", "Layout Type", layoutTypeItems)
            ]
        } as FormProps
    }
}

const newLayoutSpecificSnippet: JsonSnippetInsertOptions = {
    name: "new-layout",
    buttonText: "New Layout",
    insertLocation: "absolute",
    insertPath: ["entry", "layouts"],
    insertMode: "prepend",
    template: async data => {
        const { layoutType } = data
        if (layoutType === "GridLayout") return await gridLayoutTemplate(data)
        if (layoutType === "TabbedLayout") return await tabbedLayoutTemplate(data)
        if (layoutType === "ResponsiveLayout") return await responsiveLayoutTemplate(data)
        return await genericLayoutTemplate(data)
    },
    templateDataFormResolver: async (args: GenericEditorActionItemHandlerArgs) => {
        const lastData = getLastChainedTemplateData(args)
        const { layoutType } = lastData
        const formResolver = cond([
            [equals("GridLayout"), always(gridLayoutForm)],
            [equals("TabbedLayout"), always(tabbedLayoutForm)],
            [T, always(genericLayoutForm)]
        ])
        const form = await formResolver(layoutType)(args)
        return assocPath(["data", "layoutChildType"], "layout", form) as any
    }
}

export const appendToLayoutSnippet: JsonSnippetInsertOptions = {
    name: "new-react-dashpart",
    buttonText: "New Dashpart",
    insertLocation: "absolute",
    insertPath: async (data, templateData: any) => {
        const layoutId = path(["layoutLocation", "id"], templateData)
        const layouts = pathOr([], ["entry", "layouts"], data)
        const layoutIdIndex = findIndex(propEq("id", layoutId), layouts)
        return ["entry", "layouts", layoutIdIndex, "children"]
    },
    insertMode: "prepend",
    template: appendLayoutChildTemplate,
    templateDataFormResolver: appendLayoutChildForm
}

export const addLayoutSnippet = [newLayoutGeneralSnippet, newLayoutSpecificSnippet, appendToLayoutSnippet]
//#endregion
