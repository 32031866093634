import {
    map, compose, objOf, identity, merge, curry, contains,
    isNil, isEmpty, split, nth, trim, replace, assoc, path, dissoc, either,
    defaultTo
} from "ramda"

import {
    requiredStringInput,
    requiredJavaScriptAce, requiredJsonAce,
    tabbedFunctionEditor, markdownEditor, stringInput, dashBoardComponent,
    select
} from "../../../helpers/formControls"
import { pathString, assocPathString, parsePath } from "../../../helpers/path"
import {
    GenericEditorConfigProvider,
    GenericEditorConfig,
    GenericEditorEditor,
    GenericEditorDescriptor,
    GenericEditorAlert
} from "../../genericEditor/GenericEditorConfigProviderInterfaces"
import { JsonInsertSnippet } from "../../actionItems/JsonInsertSnippetActionItem"
import {
    addActionHandlerSnippet,
    addDataSourceSubscriptionSnippet,
    addDataSourceCommandSnippet,
    addDataSourceSubscriptionInputMapperSnippet,
    addDashPartSnippet,
    deleteDashPartActionItem,
    addLayoutSnippet,
    addFunctionMapperSnippet,
    deleteNode
} from "./snippets"

const DEPRECATED_REACT_COMPONENTS = ["DashBoardStatePipe", "Form", "FormButtonBar"]
const dashPartDeprecated = (config): boolean => {
    const { type, properties = {} } = config
    const { reactComponentName } = properties
    return type === "reactContainer" && contains(reactComponentName, DEPRECATED_REACT_COMPONENTS)
}

const generateAlert = (config): GenericEditorAlert => {
    if (dashPartDeprecated(config)) return {
        type: "error",
        message: "Component Deprecated"
    }
}

const jsonIn = d => JSON.stringify(d, null, 4)
const jsonOut = d => JSON.parse(d)
const transformPath = curry((path, trans, data) => assocPathString(path, trans(pathString(path, data)), data))
const dashBoardIn = d => { return { editorNode: d, validationMessage: null } }
const dashBoardOut = d => { return d.editorNode }

const ICON_COLORS = {
    darkBlue: "#59727d",
    lightBlue: "#519aba",
    yellow: "#cbcb41",
    orange: "#ce9178",
    purple: "#80617f",
    green: "#6a9955",
    lightGreen: "#8dc149",
    teal: "#4ec9b0"
}
const SEPARATOR = " "//" &#183; "
const genDescription = (prefix, p) => node => {
    const propValue = path(p, node)
    return `${prefix}${isNilOrEmpty(propValue) || isNilOrEmpty(prefix) ? "" : SEPARATOR}**${propValue}**`
}

const dashBoardMapperEditor: GenericEditorEditor = {
    actions: [deleteNode("Mapper", ["type"])],
    toForm: d => d.type === "FunctionMapper" ? d : transformPath("properties", jsonIn, d),
    fromForm: d => d.type === "FunctionMapper" ? d : transformPath("properties", jsonOut, d),
    controls: d => {
        {
            switch (d.type) {
                case "FunctionMapper":
                    return [tabbedFunctionEditor("properties.function", null, { height: "50vh" })]
                case "FunctionalMapper":
                    return []
                default:
                    return [requiredJsonAce("properties", "Properties")]
            }
        }
    }
}

const actionHandlerEditor: GenericEditorEditor = {
    toForm: d => d.type === "FunctionActionHandler" ? d : transformPath("properties", jsonIn, d),
    fromForm: d => d.type === "FunctionActionHandler" ? d : transformPath("properties", jsonOut, d),
    controls: d => {
        {
            switch (d.type) {
                case "FunctionActionHandler":
                    return [
                        requiredStringInput("actionName", "Action Name"),
                        tabbedFunctionEditor("properties.function", null, { height: "45vh" })
                    ]
                default:
                    return [requiredJsonAce("properties", "Properties")]
            }
        }
    }
}

const jsonNodeEditor = (description, height?): GenericEditorEditor => {
    return {
        toForm: d => objOf("data", jsonIn(d)),
        fromForm: d => jsonOut(d.data),
        controls: d => [requiredJsonAce("data", description, height)]
    }
}

const javascriptNodeEditor = (description, height?): GenericEditorEditor => {
    return {
        toForm: d => objOf("data", d),
        fromForm: d => d.data,
        controls: d => [requiredJavaScriptAce("data", description, height)]
    }
}


const configDescriptors: GenericEditorDescriptor[] = [
    {
        icon: "ri-file-pdf-fill",
        iconColor: ICON_COLORS.darkBlue,
        description: "**DashBoard**", match: "entry",
        editor: {
            actions: [
                JsonInsertSnippet(addDashPartSnippet),
                JsonInsertSnippet(addLayoutSnippet)
            ],
            toForm: (nodeData, allData) => {
                const data = dashBoardIn(nodeData)
                data["dashBoardId"] = path(["properties", "id"], allData)
                data["dashBoardTitle"] = getEntityName({ entry: nodeData })
                return { data }
            },
            fromForm: d => dashBoardOut(d.data),
            controls: d => [
                dashBoardComponent("data", null, "/id/dashboards-editor.dashboard-properties-editor")
            ]
        }
    },
    {
        sortOrder: 2,
        icon: ({ type }) => {
            return {
                ModalLayout: "ri-window-2-line",
                GridLayout: "ri-layout-grid-line",
                TabbedLayout: "ri-folder-2-line",
                PopupLayout: "ri-chat-2-line",
                FlowLayout: "ri-file-list-line",
                HiddenLayout: "ri-eye-off-line",
                ResponsiveLayout: "ri-crop-line"
            }[type] || "ri-question-mark"
        },
        iconColor: ICON_COLORS.darkBlue,
        description: path(["id"]),
        groupBy: path(["type"]),
        match: "entry.layouts",
        editor: {
            actions: [deleteNode("Layout", ["id"])],
            toForm: d => objOf("data", dashBoardIn(d)),
            fromForm: d => dashBoardOut(d.data),
            controls: d => [
                dashBoardComponent("data", null, "/id/dashboards-editor.layout-editor")
            ]
        }
    },
    {
        sortOrder: 3,
        icon: (node) => {
            const type = path(["properties", "reactComponentName"], node) as string
            return {
                Grid: "ri-layout-grid-line",
                ChangeWatcher: "ri-search-line",
                TreeView: "ri-node-tree",
                Markdown: "ri-markdown-fill",
                Button: "ri-mouse-line",
                FormButtonBar: "ri-cursor-line",
                Timer: "ri-timer-line",
                DashBoard: "ri-file-pdf-fill", //"ri-profile-line",
                HighChart: "ri-bar-chart-2-fill",
                HighChartGauge: "ri-dashboard-2-line",
                ArcGauge: "ri-dashboard-2-line",
                TextElement: "ri-text",
                EditableFormField: "ri-input-cursor-move",
                FormField: "ri-input-cursor-move",
                Frame: "ri-rounded-corner",
                Table: "fa fa-table",
                Image: "ri-file-gif-line",
                BlockGauge: "ri-funds-box-line",
                OpenLayersMap: "ri-map-2-line",
                EventTimeline: "ri-flip-vertical ri-keyboard-fill",
                Divider: "ri-separator",
                Gantt: "ri-mind-map",
                Icon: "ri-artboard-line",
                Embed: "ri-links-line",
                Video: "ri-video-line",
                Stack: "ri-stack-line",
                Tabs: "ri-folder-2-line",
                Alert: "ri-shield-line",
                Workflow: "ri-organization-chart",
            }[type] || "ri-question-mark"
        },
        iconColor: ICON_COLORS.lightBlue,
        description: genDescription("", ["id"]),
        groupBy: path(["properties", "reactComponentName"]),
        match: "entry.dashParts",
        alert: generateAlert,
        editor: {
            alert: generateAlert,
            actions: [
                JsonInsertSnippet(addFunctionMapperSnippet),
                JsonInsertSnippet(addActionHandlerSnippet),
                JsonInsertSnippet(addDataSourceSubscriptionSnippet),
                JsonInsertSnippet(addDataSourceCommandSnippet),
                deleteDashPartActionItem
            ],
            toForm: d => objOf("data", dashBoardIn(d)),
            fromForm: d => dashBoardOut(d.data),
            controls: d => [
                dashBoardComponent("data", null, "/id/a0413681-8023-4c80-bfb0-2fd04cb6bdf5")
            ]
        },
        emptyNodeText: "nothing here..."
    },

    {
        icon: "ri-database-2-fill",
        iconColor: ICON_COLORS.darkBlue,
        description: genDescription(`Subscription`, ["dataPath"]),
        match: "entry.dashParts.dataSourceSubscriptions",
        editor: {
            actions: [
                JsonInsertSnippet(addDataSourceSubscriptionInputMapperSnippet),
                deleteNode("Datasource Subscription", ["dataPath"])
            ],
            toForm: d => objOf("data", dashBoardIn(d)),
            fromForm: d => dashBoardOut(d.data),
            controls: (d, i) => {
                const dataSources = map(d => { return { text: d, value: d } }, i.data.dataSourceList as string[])
                return [
                    dashBoardComponent(
                        "data", "", "/id/f031131c-3511-4729-a504-351e6f7d0a34", {
                        dataSourceList: dataSources
                    })
                ]
            }
        }
    },
    {
        icon: "ri-guide-line ri-flip-vertical",
        iconColor: ICON_COLORS.yellow,
        description: genDescription("Input Mapper", ["type"]),
        match: "entry.dashParts.dataSourceSubscriptions.inputMappers",
        editor: dashBoardMapperEditor
    },
    {
        icon: "ri-braces-line",
        iconColor: ICON_COLORS.yellow,
        description: genDescription("Path Association", ["path"]),
        match: "entry.dashParts.dataSourceSubscriptions.inputMappers.properties.associatePaths",
        editor: {
            toForm: identity,
            fromForm: identity,
            controls: d => [requiredStringInput("path", "Path")]
        }
    },
    {
        icon: "ri-braces-line",
        iconColor: ICON_COLORS.yellow,
        description: genDescription("Expression", []),
        match: "entry.dashParts.dataSourceSubscriptions.inputMappers.properties.associatePaths.expressionPipe",
        editor: javascriptNodeEditor("Expression", "50vh")
    },
    {
        icon: "ri-braces-line", iconColor: ICON_COLORS.yellow,
        description: genDescription("Path Association", ["path"]),
        match: "entry.dashParts.mappers.properties.associatePaths",
        editor: {
            toForm: identity,
            fromForm: identity,
            controls: d => [requiredStringInput("path", "Path")]
        }
    },
    {
        icon: "ri-braces-line", iconColor: ICON_COLORS.yellow,
        description: genDescription("Expression", []),
        match: "entry.dashParts.mappers.properties.associatePaths.expressionPipe",
        editor: javascriptNodeEditor("Expression", "60vh")
    },
    {
        icon: "ri-database-2-fill", iconColor: ICON_COLORS.yellow,
        description: genDescription("Command", ["name"]),
        match: "entry.dashParts.dataSourceCommands",
        editor: merge(jsonNodeEditor("Command Properties", "50vh"), { actions: [deleteNode("Command", ["name"])] })
    },
    {
        icon: "ri-guide-line ri-flip-vertical",
        iconColor: ICON_COLORS.yellow,
        description: genDescription("Mapper", ["type"]),
        match: "entry.dashParts.mappers",
        editor: dashBoardMapperEditor
    },
    {
        icon: "ri-flashlight-fill",
        iconColor: ICON_COLORS.yellow,
        description: genDescription("Action", ["actionName"]),
        match: "entry.dashParts.actionHandlers",
        editor: merge(actionHandlerEditor, { actions: [deleteNode("Action Handler", ["type"])] })
    }
]

const isNilOrEmpty = either(isNil, isEmpty)
const isNilOrEmptyString = x => isNil(x) || isEmpty(trim(x))

const getEntityName = d => {
    const title = pathString("entry.properties.title", d)
    const description = pathString("entry.properties.description", d)
    if (isNilOrEmptyString(description) && isNilOrEmptyString(title)) return "Dashboard"
    if (!isNilOrEmptyString(title)) return title
    return trim(replace(/[\#\*]/g, "", nth(0, split("\n", description))))
}

const resolveAdditionalEntryProperties = entry => {
    const name = getEntityName(entry)
    const hasInterface = !isNilOrEmpty(path(["entry", "interface", "parameters"], entry)) ||
        !isNilOrEmpty(path(["entry", "interface", "actions"], entry))
    const explorerPath = path(["entry", "properties", "explorerPath"], entry)
    return {
        name,
        dashBoard: { name, hasInterface, explorerPath }
    }
}

const getTitle = d => {
    const { libraryServiceName = "", dashBoard = {} } = d?.properties || {}
    const explorerPath = defaultTo("", path(["entry", "properties", "explorerPath"], d))

    return `${getEntityName(d)}\n${libraryServiceName}${explorerPath}`
}

export class DashBoardEditorConfigProvider implements GenericEditorConfigProvider {
    public propertyName = "dashBoardEditorConfig"
    public createMethod = () => () => {
        const config: GenericEditorConfig = {
            explorerSortByDescription: true,
            libraryEntryType: "dashBoard",
            entityDescription: "Dashboard",
            getEntityName,
            getTitle,
            descriptors: configDescriptors,
            resolveAdditionalEntryProperties
        }
        return config
    }
}