import * as React from "react"
import { Theme, ComponentRender } from "@sennen/dashboards-react-client"
import { getLazyRequireRef } from "@sennen/dashboards-react-component"
import * as R from "ramda"

const MONACO_CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.49.0/min/vs'

export type formControlEvent = (controlId: string, value: any) => void

export interface MonacoEditorProps {
    id: string
    theme?: Theme
    componentRender?: ComponentRender,
    value?: string
    onValueEditSuccess?: (value, options?: any) => void
    height?: string
    // options: https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneEditorConstructionOptions.html
    options?: object,
    javascriptLibs?: string
    javascriptSuggestions?: Array<object>
}

const OPTION_DEFAULTS = {
    language: 'javascript',
    theme: 'vs-dark',
    automaticLayout: true,
    fixedOverflowWidgets: true
}

const loadMonaco = async () => {
    const monaco = window["monaco"] as any
    if (monaco) return monaco
    const require = await getLazyRequireRef()
    const requireP = url => new Promise((res, rej) => require(url, res))
    require.config({ paths: { vs: MONACO_CDN_URL } });
    await requireP(['vs/editor/editor.main'])
    return window["monaco"] as any
}

const onPropChangeFactory = (prevProps: MonacoEditorProps, props: MonacoEditorProps) =>
    (path: Array<string | number>, handler: (propValue: any) => void): void => {
        const curVal = R.path(path, props)
        const prevVal = R.path(path, prevProps)
        if (curVal !== prevVal) handler(curVal)
    }
export class MonacoEditor extends React.Component<MonacoEditorProps, { editorReady: boolean }> {

    private monaco
    private editorElement
    private editor
    private editorModelId: string

    constructor(props) {
        super(props)
        this.state = { editorReady: false }
    }


    componentWillUnmount() {
        this.editor.dispose()
    }

    componentDidUpdate(prevProps: MonacoEditorProps) {
        const editorModel = this.editor?.getModel()

        const newValue = this.props.value
        if (prevProps.value !== newValue) {
            if (editorModel) {
                const currentValue = editorModel.getValue() || ""
                if (currentValue !== newValue) editorModel.setValue(newValue || "")

            }
        }

        const onPropChange = onPropChangeFactory(prevProps, this.props)

        onPropChange(
            ["options", "language"],
            (language) => this.monaco?.editor.setModelLanguage(editorModel, language)
        )

    }

    async componentDidMount() {
        const component = this

        const monaco = await loadMonaco()
        this.monaco = monaco

        const { value = "", options = {}, javascriptLibs, javascriptSuggestions } = component.props
        if (javascriptLibs) {
            var libUri = 'ts:filename/injectedJsLibs.d.ts'
            const exists = !!monaco.languages.typescript.javascriptDefaults.getExtraLibs()[libUri]
            if (!exists) {
                monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                    noSemanticValidation: true,
                    noSyntaxValidation: false
                });
                monaco.languages.typescript.javascriptDefaults.addExtraLib(javascriptLibs, libUri)
            }
        }

        if (javascriptSuggestions) {
            monaco.languages.registerCompletionItemProvider('javascript', {
                provideCompletionItems: (model, ...more) => {
                    const isMe = component.editorModelId === model.id
                    let suggestions = []
                    if (isMe) {
                        suggestions = R.map<any, any>(
                            (s = {}) => ({ ...s, kind: monaco.languages.CompletionItemKind[s.kind] }),
                            javascriptSuggestions
                        )
                    }
                    return { suggestions }
                }
            })
        }


        //https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneEditorConstructionOptions.html
        const editorOptions = {
            ...OPTION_DEFAULTS,
            ...options,
            value
        }

        const editor = monaco.editor.create(component.editorElement, editorOptions)

        editor.onDidBlurEditorWidget(() => {
            const { onValueEditSuccess } = component.props
            const options = {
                cursor: editor?.getModel()?.getOffsetAt(editor?.getPosition())
            }
            onValueEditSuccess?.(editor.getValue(), options)
        })

        component.editor = editor
        component.editorModelId = component.editor.getModel().id

    }

    render() {
        const { height = "100%" } = this.props
        return <div style={{ width: "100%", height, position: "relative" }} ref={x => this.editorElement = x}></div>
    }
}
